.form {
  background-color: #fbfbfb;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
}

.form input, .form textarea {
  border: 1px solid #dbdbdb;
  width: auto;
}

.form textarea {
  font-size: 14px;
}

.form button {
  background-color: #4161db;
}

.form .required::after {
  content: "-REQUIRED";
  color: #c5c5c5;
  margin-left: 5px;
}

.error {
  gap: 5px;
  color: #fc7e7e;
}

.form .errorInput {
  border: 1px solid #fc7e7e;
}