.title {
  font-size: 14px;
  background-color: #dddddd;
  padding: 10px;
  font-weight: 700;
}

.row {
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.row:hover {
  background-color: #D5F1FA;
}