.header {
  padding: 43px 37px;
  background-color: #344584;
}

.header h1 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #ffffff;
}

.propertyImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

.propertyImages img {
  width: calc(50% - 2px);
}

.overlay {
  background-color: rgba(0,0,0,0.75);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  z-index: 1;
  margin: 0 90px;
  position: relative;
}

.modal .prevButton {
  position: absolute;
  top: 50%;
  left: -60px;
  transform: translateY(-50%) rotate(180deg);
}

.modal .nextButton {
  position: absolute;
  top: 50%;
  right: -60px;
  transform: translateY(-50%);
}

.modal .closeButton {
  position: absolute;
  top: -40px;
  right: -40px;
  padding: 4px;
  border: 4px solid #ffffff;
  transform: rotate(45deg);
  font-size: 40px;
  width: 40px;
}

.modal button {
  padding: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  background-color: unset;
}

.modal svg {
  width: 29px;
  height: 53px;
}