.container {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.title {
  font-size: 30px;
  font-weight: 400;
}

.form {
  border: 1px solid #dbdbdb;
  background-color: #fbfbfb;
  border-radius: 5px;
  padding: 20px;
  width: 324px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
}

.form :global(.contact-form) {
  padding: unset;
}