body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

select {
  width: 100%;
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  padding: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
}

button {
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  cursor: pointer;
  background-color: #1053a3;
  color: #ffffff;
}

input {
  width: calc(100% - 22px);
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  padding: 11px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  position: relative;
  top: 2px;
}

textarea {
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  padding: 10px;
  width: calc(100% - 20px);
}
