.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 36px;
}
  
.column {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  width: 100%;
}

.image {
  background-color: #999999;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  margin-bottom: 39px;
}

.mainContainer {
  max-width: 1330px;
  margin: 0 auto;
}

.mainContainer h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 161.52%;
  margin: 40px 0 14px 0;
}

.mainContainer h1 {
  margin: 0 0 2px 0;
  font-size: 30px;
  line-height: 35px;
  font-weight: 400;
}

.memberTitle {
  font-size: 24px;
  line-height: 161.52%;
  color: #777777;
  margin: 0 0 60px 0;
}

.memberDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
}

.description {
  font-size: 24px;
  line-height: 161.52%;
  padding-left: 30px;
  white-space: pre-line;
}

.memberDetail ul {
  display: grid;
  gap: 20px;
}