.container {
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  position: relative;
  background-color: #344584;
  z-index: 2;
}

.logo {
  position: absolute;
  top: 20px;
  left: 60px;
}

.links {
  position: absolute;
  top: 50px;
  right: 60px;
}

.navMenu {
  color: #ffffff;
  margin: 10px;
  font-size: 16px;
  font-weight: 500;
}

.navMenuActive {
  color: #66aaee;
  margin: 10px;
  font-size: 16px;
  font-weight: 500;
}
