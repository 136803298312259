.paper {
  display: flex;
  flex-direction: column;
  width: 280px;
  gap: 10px;
  cursor: pointer;
  color: #000;
}

.image {
  width: 280px;
  height: 210px;
  object-fit: cover;
  border-radius: 5px;
}

.row {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
}

.label {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  padding: 3px 6px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.price {
  font-size: 30px;
  font-weight: 300;
}

.address {
  font-size: 16px;
  font-weight: 500;
  color: #1053A3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 260px;
}