.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: #344584;
  color: #ffffff;
  font-size: 12px;
  gap: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.flexWraper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.office {
  width: 250px;
}