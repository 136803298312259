.mainContainer {
  max-width: 1330px;
  margin: 0 auto;
}

.row {
  display: flex;
  align-items: top;
  width: 100%;
  gap: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

.flexWraper {
  position: relative;
  display: flex;
  align-items: top;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 640px;
  max-height: 650px;
  min-height: 650px;
  overflow-y: auto;
}

.more {
  left: 0px;
  top: 590px;
  position: absolute;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  padding: 10px 0px;
  font-size: 12px;
}

.footer {
  display: flex;
  flex-direction: column;
  background-color: #344584;
  color: #ffffff;
  padding: 30px;
  font-size: 12px;
  line-height: 20px;
}

.buttonLight {
  background-color: #ffffff;
  color: #344584;
  border: solid 1px #344584;
  border-radius: 5px;
  padding: 10px 20px;
}

.buttonDark {
  background-color: #344584;
  color: #ffffff;
  border: solid 1px #344584;
  border-radius: 5px;
  padding: 10px 20px;
}

.map {
  width: 800px;
  height: 960px;
  position: fixed;
  top: 100px;
  z-index: 1;
}

.filters {
  width: 650px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fbfbfb;
  border: 1px solid #dedede;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  padding: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loader {
  width: 640px;
  text-align: center;
  padding-top: 200px;
}
