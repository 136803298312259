.titleRow {
  background-color: #344584;
  color: #ffffff;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 400;
}

.titleContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 80px 20px 80px;
}

.titleContent > div {
  display: flex;
  flex-direction: column;
}

.titleRow .titleRowLeft {
  max-width: 832px;
}

.titleRow .titleRowRight {
  text-align: end;
}

.titleRow .titleAddress,
.titleRow .titlePrice {
  font-size: 28px;
  line-height: 33.89px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 8px 0;
}

.titleAddress {
  white-space: pre;
}

.propertyImages {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 500px;
  background-color: #363636;
  position: relative;
}

.propertyImages > img:first-child {
  height: 100%;
  width: 50%;
  object-fit: cover;
  object-position: center;
}

.propertyImages > img:not(:first-child) {
  height: 50%;
  width: 25%;
  object-fit: cover;
  object-position: center;
}

.propertyImages.total-image-count-1 {
  align-items: center;
}

.propertyImages.total-image-count-1 > img:first-child {
  width: 60%;
}

.propertyImages.total-image-count-2 > img:not(:first-child) {
  height: 100%;
  width: 50%;
}

.propertyImages.total-image-count-3 > img:first-child,
.propertyImages.total-image-count-4 > img:first-child {
  width: 67%;
}

.propertyImages.total-image-count-3 > img:not(:first-child),
.propertyImages.total-image-count-4 > img:not(:first-child) {
  width: 33%;
}

.propertyImages .viewGallery {
  width: 25%;
  height: 50%;
  position: relative;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.propertyImages .viewGallery::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.propertyImages .viewGallery img {
  position: relative;
  z-index: 1;
}

.propertyImages .viewGallery span {
  position: relative;
  z-index: 1;
  color: #ffffff;
  font-size: 14px;
  margin-top: 16px;
}

.propertyImages > a {
  position: absolute;
  width: 126px;
  height: 35px;
  background-color: #344584;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  top: 15px;
}

.propertyImages > a:last-of-type {
  right: 174px;
}

.propertyImages > a:first-of-type {
  right: 26px;
}

.propertyImages > a span {
  margin-left: 8px;
}

.anchorRow {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  font-size: 16px;
  font-weight: 500;
  color: #727272;
  z-index: 101; /* 100 is the z-index of the bing map button */
}

.anchors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 80px;
}

.anchors > div {
  padding: 20px;
  cursor: pointer;
}

.anchorRow .activeAnchor {
  color: #000000;
  border-bottom: 2px solid #000000;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px 0;
  padding: 30px 80px;
}

.mainContainer h2 {
  margin: 0 0 14px 0;
  text-transform: uppercase;
}

.anchorContainer {
  position: relative;
}

.anchorContainer .anchor {
  position: absolute;
  top: -70px;
}

.keyFactContainer {
  display: flex;
  flex-direction: row;
}

.keyFactContainer > div {
  width: 50%;
  flex-shrink: 0;
}

.keyFactContainer > div > div:not(:last-child) {
  margin-bottom: 18px;
  display: flex;
}

.keyFactContainer > div label {
  display: inline-block;
  width: 120px;
  font-weight: 700;
  color: #414141;
  flex-shrink: 0;
}

.keyFactContainer > div span {
  word-break: break-word;
}

.propertyDescription,
.keyFactContainer,
.documentsContainer {
  margin-bottom: 60px;
  white-space: pre-wrap;
}

.file {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.file:not(:last-child) {
  margin-bottom: 20px;
}

.file svg {
  margin-right: 17px;
}

.file a {
  color: #3d7fff;
}

.requestInfoColumn {
  width: 387px;
  margin-left: 57px;
  flex-shrink: 0;
}

.realtorCard,
.contactForm {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  overflow: hidden;
}

.realtorCard:not(:last-child) {
  margin-bottom: 14px;
}

.realtorCard :global(.contact-form) {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: 0;
}

.realtorCard.activeRealtor :global(.contact-form) {
  max-height: 1000px;
  padding: 20px;
}

.realtorCard button {
  width: 100%;
  background-color: #4161db;
  padding-top: 17px;
  padding-bottom: 17px;
}

.realtorCard input {
  width: calc(100% - 20px);
}

.realtorCard img {
  object-fit: cover;
  object-position: top;
}

.realtorInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 40px;
  border-bottom: 1px solid #efefef;
  gap: 10px;
}

.realtorInfo img {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  margin-right: 17px;
}

.realtorInfo > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.realtorInfo > div span {
  line-height: 16px;
}

.realtorPhone {
  margin-bottom: 5px;
}

.realtorInfo > div span.realtorName {
  font-size: 18px;
  color: #27417a;
  font-weight: 500;
  margin-bottom: 1px;
  line-height: 21px;
}

.realtorTitle {
  margin-bottom: 10px;
  font-weight: 500;
}

button.requestInfoExpandButton {
  border-radius: 0;
}

.propertyAddress {
  margin-bottom: 23px;
  font-weight: 700;
}

:global(#bingMap),
.mapPlaceholder {
  height: 621px;
  width: 100%;
}

.mapPlaceholder {
  background-image: url("../images/map-placeholder.png");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.mapPlaceholder svg {
  margin-bottom: 30px;
}

.mapPlaceholder button {
  padding: 17px 50px;
  background-color: #4161db;
  font-weight: 500;
}

.anchors,
.mainContainer,
.titleContent {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
}

.disclaimer {
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 48px;
}
