.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 36px;
}

.column {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  width: 100%;
}

.banner {
  width: 100%;
  height: 300px;
  background-image: url(../../public/about.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.image {
  background-color: #999999;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  height: 320px;
  min-height: 320px;
  max-height: 320px;
  object-fit: cover; 
}

.description {
  font-size: 20px;
  line-height: 1.5em;
  max-height: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  width: 600px;
}

.more{
  font-size: 14px;
  color: #0047FF;
  text-align: right;
  cursor: pointer;
}

.mainContainer {
  max-width: 1330px;
  margin: 0 auto;
}

.mainContainer h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 161.52%;
  margin: 40px 0 14px 0;
}

.mainContainer h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  margin: 36px 0 8px 0;
}

.memberInfo {
  padding: 20px 0;
}

.memberInfo h3 {
  margin: 0 0 5px 0;
  font-size: 30px;
  line-height: 35px;
  font-weight: 400;
}

.memberInfo .memberTitle {
  font-size: 24px;
  line-height: 161.52%;
  color: #777777;
  margin-bottom: 37px;
}

.memberInfo a {
  margin-top: 10px;
}

.teamMembers {
  padding: 30px 0 30px 30px;
  gap: 20px;
  display: grid;
}