.mainContainer {
  max-width: 1330px;
  margin: 0 auto;
}

.mainContainer h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 161.52%;
  margin: 40px 0 14px 0;
}

.mainContainer h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  margin: 36px 0 8px 0;
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 36px;
}

.column {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  width: 100%;
  margin-bottom: 500px;
}

.banner {
  width: 100%;
  height: 300px;
  background-image: url(../../public/seller.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bannerText {
  margin: 20px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
}

.title {
  font-size: 30px;
}

