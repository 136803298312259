.header {
  height: 360px;
  max-height: 360px;
  min-height: 360px;
  background-image: url(../../public/banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.logo {
  position: absolute;
  top: 40px;
  left: 60px;
}

.links {
  position: absolute;
  top: 50px;
  right: 60px;
}

.search {
  width: 100%;
  position: absolute;
  top: 170px;
  left: 0px;
}

.row {
  display: flex;
  gap: 10px;
  width: 80%;
  margin: auto;
}

.navMenu {
  color: #ffffff;
  margin: 10px;
  font-size: 16px;
  font-weight: 500;
}

.title {
  font-size: 24px;
  font-weight: 700;
  width: 1200px;
  max-width: 1200px;
  margin: 20px auto;
}

.flexWraper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 1200px;
  max-width: 1200px;
  margin: 10px auto;
  min-height: 200px;
}
