.mainContainer {
  max-width: 1330px;
  margin: 0 auto;
  padding: 20px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  width: 150px;
}

.flexWraper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 1200px;
  max-width: 1200px;
  margin: 10px auto;
  min-height: 200px;
}
