.root {
  background-color: #fefefe;
  color: #494949;
  margin: auto;
  min-width: 1440px;
  position: relative;
}

.children {
  min-width: 1440px;
}

